import { componentsConfig as defaultConfig } from "srs.sharedcomponents/lib/esm/components/config";
import EnabledOktaSecurityComponent from "srs.sharedcomponents/lib/esm/components/oktaSecurity/EnabledOktaSecurity/EnabledOktaSecurity";
import LoginOktaComponent from "srs.sharedcomponents/lib/esm/components/pages/Login/Okta/LoginOkta";
import LoginOktaCallbackComponent from "srs.sharedcomponents/lib/esm/components/pages/Login/Okta/LoginOktaCallback/LoginOktaCallback";
import ProductListComponent from "srs.sharedcomponents/lib/esm/shared-components/srs.productList.component";
import FooterBuilderComponent from "srs.sharedcomponents/lib/esm/components/organisms/Footer/FooterBuilder/FooterBuilder";
import ZuoraDropIn from "srs.sharedcomponents/lib/esm/components/checkout/srs.zuoraDropIn.component"
import MyZuoraSubscriptions from "srs.sharedcomponents/lib/esm/components/pages/MySubscriptions/MyZuoraSubscriptions"

const componentsConfig = { 
    ...defaultConfig, 
    HomePage: ProductListComponent,
    OktaSecurity: EnabledOktaSecurityComponent,
    LoginPage: LoginOktaComponent,
    LoginCallbackPage: LoginOktaCallbackComponent,
    FooterContent: FooterBuilderComponent,
    PaymentProcessor: ZuoraDropIn,
    MySubscriptions: MyZuoraSubscriptions,
};

export { componentsConfig };