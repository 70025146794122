import {
  Colors,
  globalTheme,
} from "srs.sharedcomponents/lib/esm/styles/config";

const allisonThemeColor = "#135eab";
export const customTheme = {
  ...globalTheme,
  colors: {
    ...globalTheme.colors,
    primary: allisonThemeColor,
    navTextButtonColor: Colors.black,
    formLabel: Colors.black,
    menuButton: Colors.white,
    menuButtonMobile: Colors.black,
  },
};
