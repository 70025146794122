import {
  appConfig as packageAppConfig,
  IConfig,
} from "srs.sharedcomponents/lib/esm/config/app.config";

export const appConfig: IConfig = {
  ...packageAppConfig,
  enableFieldLoanCheckoutRepairOrderNumber: true,
  oktaSettings: {
    clientId: window.env.OKTA_CLIENT_ID,
    issuer: window.env.OKTA_ISSUER,
    redirectUri: window.env.OKTA_REDIRECT_URI,
    postLogoutRedirectUri: window.env.OKTA_POST_LOGOUT_REDIRECT_URI,
    scopes: ['groups', 'Allison', 'AllisonDOC', 'openid'],
    pkce: true,
    disableHttpsCheck: false,
  },
  zuoraSettings: {
    id: window.env.ZUORA_ID,
    url: window.env.ZUORA_CHECKOUT_URL,
  },
};
